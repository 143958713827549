<template>
  <div class="login">
    <div class="page-title">
      <h1>Login</h1>
    </div>
    <b-row>
      <b-col cols="4" offset="4" class="content">
        <b-alert variant="danger" :show="error">Error logging in, please try again</b-alert>
        <b-form-group label="E-mail">
          <b-form-input type="email" v-model="email"></b-form-input>
        </b-form-group>
        <b-form-group label="Password">
          <b-form-input type="password" @keyup.enter="login()" v-model="password"></b-form-input>
        </b-form-group>
        <b-form-group>
          <b-button variant="link" :to="{name: 'SignUp'}">No account? sign up here</b-button>
          <b-button class="float-right" variant="success" @click="login" v-if="!loading">Login</b-button>
          <b-spinner v-else></b-spinner>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import api from '../api'
import {mapState} from 'vuex'

export default {
  name: 'Login',
  data() {
    return {
      email: '',
      password: '',
      loading: false,
      error: false
    }
  },
  computed: {
    ...mapState(['token'])
  },
  created() {
    if (this.token) {
      this.$router.push({name: 'Sites'})
    }
  },
  methods: {
    login() {
      this.loading = true
      api.user().login(this.email, this.password).then(token => {
        this.$store.commit('login', token)
        this.$store.commit('getUser')
        this.$router.push({name: 'Sites'})
      }).catch(() => {
        this.error = true
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
