<template>
  <div class="dashboard">

    <div class="page-title">
      <h1>Sites</h1>
    </div>

    <b-row>
      <b-col class="content">
        <b-table :items="sites" :fields="['name', 'status', 'domain', 'actions']">
          <template v-slot:cell(status)="{item}">
            <site-status :site="item" :working="false" :badge="true"></site-status>
          </template>
          <template v-slot:cell(actions)="{item}">
            <b-button variant="danger" class="mr-2" @click="remove(item)">Delete</b-button>
            <b-button v-if="item.deployable"
                      :to="{name: 'DeploySite', params: {id: item.id}}" variant="primary">deploy
            </b-button>
            <b-button v-if="item.deployable" class="mr-2 ml-2"
                      :to="{name: 'SiteDomains', params: {id: item.id}}" variant="primary">Domain & SSL
            </b-button>
            <b-button v-else :to="{name: 'CreateSite', query: {id: item.id}}">
              Continue Setup
            </b-button>
          </template>
        </b-table>
        <b-alert :show="this.sites.length === 0">
          You currently don't have any sites, go add your first one! The first 7 days are on us!
        </b-alert>
        <b-button variant="success" :to="{name: 'CreateSite'}">Add new site</b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import api from '../api'
import {mapState} from 'vuex'
import SiteStatus from './SiteStatus'

export default {
  name: 'Dashboard',
  components: {
    SiteStatus
  },
  data() {
    return {
      sites: [],
      stripe: null
    }
  },
  methods: {
    remove(site) {
      if (!confirm('Are you really sure you want to delete ' + site.name + '?')) {
        return
      }
      api.site().remove(site.id).then(() => {
        this.getSites()
      })
    },
    getSites() {
      api.site().mine().then(sites => {
        this.sites = sites.map((site) => {
          site.deployable = site.status === 'DEPLOYED' || site.status === 'DEPLOYING' || site.status === 'DEPLOYMENT_FAILED'
          return site
        })
      })
    }
  },
  computed: {
    ...mapState(['user'])
  },
  created() {
    this.getSites()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
