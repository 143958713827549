<template>
  <div class="content">
    <b-row>
      <b-col v-if="site">
        <h1>Step 1 of 3 - Create a new site</h1>
        <p>In this step we will create your project and add it to our cluster.</p>
        <template v-if="site.status === 'NEW'">
          <b-form-group label="Project Name (only visible to you)">
            <b-form-input v-model="site.name"></b-form-input>
          </b-form-group>
          <b-form-group label="Package">
            <b-form-select value-field="id" text-field="name" :options="packages" v-model="site.package_id"></b-form-select>
            <p>Don't worry, you can always upgrade</p>
          </b-form-group>
          <b-form-group>
            <b-button v-if="!working" @click="createSite()">Next</b-button>
          </b-form-group>
        </template>
        <hr>
        <h1>Step 2 of 3 - Configure git</h1>
        <p>In this step we will set-up a git integration with a deploy key</p>
        <b-alert variant="danger" :show="true" v-for="error in errors" :key="error">{{ error }}</b-alert>
        <template v-if="site.status === 'CREATED_SSH_KEY' || site.status === 'INSTALL_GIT_FAILED'">
          <b-form-group label="Git repository" description="Please provide a ssh git url (e.g. git@github.com:username/git-repo-name.git)">
            <b-form-input v-model="site.git_repo"></b-form-input>
          </b-form-group>
          <b-form-group label="Git branch to clone">
            <b-form-input v-model="site.git_branch"></b-form-input>
          </b-form-group>
          <b-form-group label="">
            <b-form-checkbox v-model="site.composer_install">Run composer install</b-form-checkbox>
          </b-form-group>
          <p>You <strong>must add</strong> the following SSH deploy key to your git provider before your application can be installed</p>
          <b-form-group>
            <b-textarea rows="6" v-model="site.public_key" disabled></b-textarea>
          </b-form-group>
          <b-form-group>
            <b-button variant="success" @click="install()" :disabled="working">Install</b-button>
          </b-form-group>
        </template>
        <hr>
        <h1>Step 3 of 3 - Do your first deployment!</h1>
        <template v-if="site.status === 'DEPLOYED'">
          <b-alert variant="success" show>
            Your application has been deployed!
          </b-alert>
          <b-button variant="success" :to="{name: 'DeploySite', params: {id: site.id}}">Go to site deployment</b-button>
        </template>
      </b-col>
      <b-col cols="4">
        <site-status v-if="site" :site="site" :working="working"></site-status>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import api from '../api'
import SiteStatus from './SiteStatus'
export default {
  components: {
    SiteStatus
  },
  name: 'CreateSite',
  data() {
    return {
      working: false,
      site: null,
      timeout: null,
      log: null,
      packages: [],
      errors: []
    }
  },
  methods: {
    pullStatus() {
      api.site().get(this.site.id).then((site) => {
        this.site = site
        if(site.status === 'NEW') {
          this.working = false
        } else if (site.status === 'INSTALL_GIT_FAILED') {
          this.working = false
        } else if (site.status === 'CREATED_SSH_KEY') {
          // Stop showing spinner
          this.working = false
          this.$router.push({name: 'CreateSite', query: {id: this.site.id}})
        } else if(site.status === 'INSTALLED_GIT') {
          this.working = false
        } else {
          this.working = true
          this.timeout = setTimeout(this.pullStatus, 2000)
        }
      }).catch(() => {
        this.working = false
      })
    },
    createSite() {
      this.working = true
      api.site().create(this.site).then((site) => {
        this.site = site
        this.pullStatus()
      }).catch(() => {
        this.working = false
      })
    },
    install() {
      this.errors = []
      if (!this.site.git_repo) {
        this.errors.push('Please enter a git repository.')
        return
      }

      // eslint-disable-next-line
      if(!this.site.git_repo.toString().match(/^([A-Za-z0-9]+@)([A-Za-z0-9.]+(:\d+)?)(?::|\/)([\d\/\w.-]+?)(\.git)?$/)) {
        this.errors.push('Please enter a SSH git repository url.')
        alert('no-match')
        return
      }

      this.working = true

      api.site().checkGitAccess(this.site).then((result) => {
        if(!result.access) {
          this.errors.push('Unable to connect to git repository, please make sure your key is added')
          this.errors.push(result.output)
          this.working = false
          return
        }

        if (!result.has_branch) {
          this.errors.push('The specified git branch does not exists remotely')
          this.working = false
          return
        }

        api.site().install(this.site).then((site) => {
          this.site = site
          this.pullStatus()
        })
      })

    }
  },
  created() {
    if (this.$route.query.id) {
      this.working = true
      api.site().get(this.$route.query.id).then(site => {
        this.site = site
        this.pullStatus()
      })
    } else {
      this.working = false
      this.site = {status: 'NEW'}
    }
    api.billing().packages().then((packages) => {
      this.packages = packages
    })
  },
  beforeDestroy() {
    if(this.timeout){
      clearTimeout(this.timeout)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
