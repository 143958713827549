<template>
  <div>
    <div class="header pt-4">
      <b-container fluid>
        <b-row>
          <b-col class="text-center">
            <img style="height: 40px" src="@/assets/logo.svg"/>
            <h1 class="mt-3">
              LaravelHosting.cloud
            </h1>
          </b-col>
        </b-row>
      </b-container>
      <b-container>
        <b-row v-if="user">
          <b-col>
            <b-nav justified tabs>
              <b-nav-item :active="$route.name === 'Sites'" :to="{name: 'Sites'}">Sites</b-nav-item>
              <b-nav-item :active="$route.name === 'Billing'" :to="{name: 'Billing'}">Billing</b-nav-item>
              <b-nav-item v-if="user.admin" :active="$route.name === 'Users'" :to="{name: 'Users'}">Users</b-nav-item>
              <b-nav-item @click="logout()">Logout <span v-if="impersonating">{{ user.email }}</span></b-nav-item>
            </b-nav>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <b-container :fluid="$route.name === 'Users'">
      <b-row>
        <b-col>
          <router-view v-slot="{ Component }">
            <transition>
              <keep-alive>
                <component :is="Component"/>
              </keep-alive>
            </transition>
          </router-view>
        </b-col>
      </b-row>
    </b-container>
  </div>

</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'App',
  computed: {
    ...mapState(['token','user', 'impersonating'])
  },
  created(){
    if (this.token && !this.user) {
      this.$store.commit('getUser')
    }
  },
  methods: {
    logout(){
      this.$store.commit('logout')
      this.$router.push({name: 'Login'})
    }
  }
}
</script>

<style lang="scss">
html {
  height: 100%;
}
body {
  height: 100%;
  margin: 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-image: linear-gradient(45deg, #fe2983 0, #ff6f61 50%, #fc9e21 110%);
}

.content {
  background: white;
  border-radius: 25px;
  padding: 25px;
}

.header {
  //background-image: linear-gradient(
  //        45deg, #fe2983 0, #ff6f61 50%, #fc9e21 110%);
  background: white;
  margin-bottom: 2rem;
}

.header h1 {
  font-size: 32px;
}


.page-title * {
  color: white;
}

.page-title {
  padding-bottom: 25px;
  text-align: center;
}


h1, h2, h3, h4, h5 {
  color: #27333e;
}

// overrides
$enable-shadows: true;
$enable-rounded: true;

// Bootstrap and its default variables
@import '../node_modules/bootstrap/scss/bootstrap';
// BootstrapVue and its default variables
@import '../node_modules/bootstrap-vue/src/index.scss';


* {
  font-family: "Lato", sans-serif !important;
}
</style>
