export default class userApi {

    constructor(axios) {
        this.axios = axios
    }

    create(site) {
        return this.axios.post('/site', site)
    }

    get(id) {
        return this.axios.get('/site/' + id)
    }

    env(id) {
        return this.axios.get('/site/' + id + '/env')
    }

    setEnv(id, env) {
        return this.axios.post('/site/' + id + '/env', {env})
    }

    deploy(id) {
        return this.axios.post('/site/' + id + '/deploy')
    }

    update(site) {
        return this.axios.post('/site/' + site.id, {
            domain: site.domain
        })
    }

    install(site) {
        return this.axios.post('/site/' + site.id + '/install', site)
    }

    mine() {
        return this.axios.get('/site/mine')
    }

    remove(id) {
        return this.axios.post('/site/' + id + '/delete')
    }

    addDomain(id, domain) {
        return this.axios.post('/site/' + id + '/domain', {domain})
    }

    removeDomain(id, domainId) {
        return this.axios.delete('/site/' + id + '/domain/' + domainId)
    }

    getLastDeployment(id) {
        return this.axios.get('/site/' + id + '/last-deploy-log')
    }

    checkGitAccess(site) {
        return this.axios.post('/site/' + site.id + '/check-git-access', site)
    }

    openForge(id) {
        return this.axios.get('/site/' + id + '/open-forge')
    }
}
