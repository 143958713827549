<template>
  <div class="login">
    <b-row>
      <b-col cols="6" offset="3">

        <div class="page-title">
          <h1>Try-for free!</h1>
          <h2>Get started in minutes!</h2>
          <h3>First, let's create your account.</h3>
        </div>

        <div class="content">
          <b-alert variant="danger" :show="errors.length > 0">
            Something went wrong. Please try again
            <ul>
              <li v-for="error in errors" :key="error">{{ error }}</li>
            </ul>
          </b-alert>
          <b-form-group label="Name / company">
            <b-form-input type="email" v-model="user.name"></b-form-input>
          </b-form-group>
          <b-form-group label="E-mail">
          <b-form-input type="email" v-model="user.email"></b-form-input>
        </b-form-group>
          <b-form-group label="Password">
            <b-form-input type="password" v-model="user.password"></b-form-input>
          </b-form-group>
          <b-form-group label="Repeat password">
            <b-form-input type="password" v-model="password_check"></b-form-input>
          </b-form-group>
          <b-form-group label="">
            <b-form-checkbox type="password" v-model="tos">
              I agree with the <a href="https://laravelhosting.cloud/tos" target="_blank">Terms of Service</a>
            </b-form-checkbox>
          </b-form-group>
          <b-form-group>
            <b-button variant="link" :to="{name:'Login'}">I already have an account</b-button>
            <b-button class="float-right" variant="success" @click="signup" v-if="!loading">Sign up!</b-button>
            <b-spinner v-else></b-spinner>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import api from '../api'

export default {
  name: 'Login',
  data() {
    return {
      user: {
        name: '',
        email: '',
        password: ''
      },
      password_check: '',
      loading: false,
      errors: [],
      password_error: false,
      tos: false
    }
  },
  methods: {
    signup() {
      this.errors = []
      if (this.user.password.length < 6) {
        this.errors.push('Your password should be at least 6 characters.')
      }

      if (this.user.password !== this.password_check) {
        this.errors.push('The entered passwords do not match, please try again')
      }

      if(this.user.name.length === 0) {
        this.errors.push('The name field is required')
      }

      if(this.user.email.length === 0) {
        this.errors.push('Please enter a email address')
      }

      if(!this.tos) {
        this.errors.push('You msut agree with the terms of service in order to sign up')
      }

      if(this.errors.length > 0) {
        return
      }

      this.loading = true
      api.user().signup(this.user).then(token => {
        this.$store.commit('login', token)
        this.$router.push({name: 'Sites'})

        // Perform the tracking
        this.$gtag.event('sign_up')
        this.$gtag.event('conversion', {'send_to': 'AW-939836725/ZCERCOuLqv8BELWKk8AD'});

      }).catch((error) => {
        console.log(error)
        if(error.response){
          this.errors = error.response.data
        }
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
