<template>
  <div>
    <div class="page-title">
      <h1>Deploy your site {{ site.name }}</h1>
    </div>
    <b-row class="content">
      <b-col>
        <site-details :site="site"></site-details>
        <b-row class="mt-3">
          <b-col>
            <h2>Edit .env file</h2>
            <b-form-group v-if="!env">
              <b-button variant="info" :disabled="working" @click="getEnv()">Edit .env file</b-button>
            </b-form-group>
            <b-form-group v-else>
              <b-button variant="success" :disabled="working" @click="setEnv">Update .env file</b-button>
              <b-button class="float-right" variant="warning" :disabled="working" @click="env = null">cancel</b-button>
            </b-form-group>
            <b-form-group v-if="env">
              <b-textarea rows="30" v-model="env"></b-textarea>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="4">
        <div class="pt-3">
        <site-status :site="site" :working="working"></site-status>
        </div>
        <b-form-group>
          <b-button block @click="deploy()" :disabled="working" variant="success">Deploy!</b-button>
          <b-button v-if="log" variant="info" :disabled="working"  block v-b-modal.modal-log>Show latest deployment log</b-button>
        </b-form-group>
      </b-col>
    </b-row>

    <b-modal id="modal-log" title="Latest deployment log" size="xl" :ok-only="true">
      <pre class="my-4">{{ log }}</pre>
    </b-modal>
  </div>
</template>

<script>
import api from '../api'
import SiteStatus from './SiteStatus'
import SiteDetails from './SiteDetails'

export default {
  name: 'DeploySite',
  components: {
    SiteStatus,
    SiteDetails
  },
  data() {
    return {
      site: {},
      working: false,
      env: null,
      editEnv: false,
      showDbPassword: false,
      log: null
    }
  },
  computed: {
    status() {
      if (!this.site.status) {
        return 'LOADING'
      }
      return this.site.status
    }
  },
  methods: {
    showLastLog() {
      alert(this.log)
    },
    pullStatus() {
      api.site().get(this.site.id).then((site) => {
        this.site = site
        if (site.status === 'DEPLOYED') {
          // Stop showing spinner
          this.working = false
        } else {
          this.working = true
          setTimeout(this.pullStatus, 2000)
        }
      }).catch(() => {
        this.working = false
      })
    },
    deploy() {
      this.working = true
      api.site().deploy(this.site.id).then(() => {
        this.pullStatus()
      })
    },
    getEnv() {
      this.working = true
      api.site().env(this.site.id).then((env) => {
        this.env = env
        this.working = false
      })
    },
    setEnv() {
      this.working = true
      api.site().setEnv(this.site.id, this.env).then(() => {
        this.working = false
        this.env = null
      })
    }
  },
  created() {
    this.working = true
    api.site().get(this.$route.params.id).then((site) => {
      this.site = site
      this.working = false
      api.site().getLastDeployment(this.site.id).then(log => {
        this.log = log
      })
    })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
