import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
import api from '../api'
let startToken = localStorage.getItem('token')
let isImpersonating = false
if(localStorage.getItem('impersonate_token')) {
    startToken = localStorage.getItem('impersonate_token')
    isImpersonating = true
}

const store = new Vuex.Store({
    state: {
        token: startToken,
        user: null,
        impersonating: isImpersonating
    },
    mutations: {
        login(state, token) {
            state.token = token
            state.impersonating = false
            localStorage.setItem('token', state.token)
        },
        loginAs(state, token) {
            state.token = token
            state.impersonating = true
            localStorage.setItem('impersonate_token', token)
        },
        logout(state) {
            if (state.impersonating) {
                localStorage.removeItem('impersonate_token')
                state.impersonating = false
                state.token = localStorage.getItem('token')
                api.user().me().then(user => {
                    state.user = user
                })
                return
            }
            localStorage.removeItem('token')
            state.token = null
            document.location = '/'
        },
        getUser(state) {
            api.user().me().then(user => {
                state.user = user
            })
        }
    }
})

export default store
