export default class billingApi {

    constructor(axios) {
        this.axios = axios
    }

    packages() {
        return this.axios.get('/package')
    }

    pay(id) {
        return this.axios.get('/site/' + id + '/pay')
    }
}
