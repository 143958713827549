import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/components/Login'
import Sites from '@/components/Sites'
import CreateSite from '@/components/CreateSite'
import DeploySite from '@/components/DeploySite'
import SiteDomains from '@/components/SiteDomains'
import SignUp from '@/components/SignUp'
import Billing from '@/components/Billing'
import Users from '@/components/Users'

Vue.use(Router)

const router = new Router({
    routes: [
        {
            path: '/',
            name: 'Login',
            component: Login
        },
        {
            path: '/sites',
            name: 'Sites',
            component: Sites
        },
        {
            path: '/site/create',
            name: 'CreateSite',
            component: CreateSite
        },
        {
            path: '/site/:id/deploy',
            name: 'DeploySite',
            component: DeploySite
        },
        {
            path: '/site/:id/domains',
            name: 'SiteDomains',
            component: SiteDomains
        },
        {
            path: '/signup',
            name: 'SignUp',
            component: SignUp
        },
        {
            path: '/billing',
            name: 'Billing',
            component: Billing
        },
        {
            path: '/users',
            name: 'Users',
            component: Users
        }
    ]
})

export default router;
