import axios from 'axios'
import userApi from './user'
import billingApi from './billing'
import siteApi from './site'
import store from '../store'

if (window.location.host === 'localhost:8080') {
    axios.defaults.baseURL = 'http://cluster.laravelhosting.local'
} else {
    axios.defaults.baseURL = 'https://cluster.laravelhosting.cloud'
}

axios.interceptors.request.use((config) => {
    let token = store.state.token
    if (token) {
        config.headers['X-Api-Key'] = token
    }
    return config
}, (error) => {
    // Do something with request error
    document.location = '/'
    return Promise.reject(error)
})

axios.interceptors.response.use((response) => {
    return response.data
}, (error) => {
    // Do something with response error
    if (error.response.status === 401) {
        store.commit('logout')
    }
    return Promise.reject(error)
})

const user = () => {
    return new userApi(axios)
}
const site = () => {
    return new siteApi(axios)
}

const billing = () => {
    return new billingApi(axios)
}

export default {user, site, billing}
