export default class userApi {

    constructor(axios) {
        this.axios = axios
    }

    login(email, password) {
        return this.axios.post(`/user/login`, {email, password})
    }

    signup(user) {
        return this.axios.post(`/user/signup`, user)
    }

    me() {
        return this.axios.get('/user/me')
    }

    all() {
        return this.axios.get('/user')
    }

    loginAs(id) {
        return this.axios.post('/user/impersonate/' + id)
    }

    delete(id) {
        return this.axios.delete('/user/' + id)
    }
}
