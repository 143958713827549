<template>
  <div>
    <b-badge v-if="badge" :variant="variant" >
      {{ status }}
    </b-badge>
    <b-alert v-else :variant="variant" show>
      <b-spinner small v-if="working"></b-spinner>
      {{ status }}
    </b-alert>
  </div>
</template>
<script>
export default {
  props: ['site', 'working', 'badge'],
  computed: {
    status() {
      return this.labels[this.site.status] ?? this.site.status
    },
    variant() {
      return this.variants[this.site.status] ?? 'info'
    }
  },
  data() {
    return {
      labels: {

        NEW: 'Ready to create site',

        ADDING_TO_SERVER: 'Adding site to server',
        ADDED_TO_SERVER: 'Site added to server',

        CREATING_DATABASE: 'Creating database',
        CREATED_DATABASE: 'Database created',

        CREATING_ENV_FILE: 'Creating .env file',
        ENV_FILE_CREATED: '.env file created',

        CREATING_SSH_KEY: 'Creating ssh keys',
        CREATED_SSH_KEY: 'SSH Keys created',

        INSTALLING_GIT: 'Installing git repository',
        INSTALLED_GIT: 'Git repository installed',
        INSTALL_GIT_FAILED: 'Installation failed, please check your ssh key and re-try',

        DEPLOYMENT_FAILED: 'Deployment failed',
        DELETING: 'Deleting',
        DEPLOYED: 'Deployment complete!',
        DEPLOYING: 'Deploying...'
      },
      variants: {
        CREATED_DATABASE: 'success',
        ADDED_TO_SERVER: 'success',
        DEPLOYED: 'success',
        DEPLOYMENT_FAILED: 'warning',
        DELETING: 'warning',
        CREATED_SSH_KEY: 'success',
        INSTALLED_GIT: 'success',
        ENV_FILE_CREATED: 'success',
        INSTALL_GIT_FAILED: 'danger'
      }
    }
  }
}
</script>
