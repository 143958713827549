<template>
  <div v-if="site && site.server">
    <h2>Site details</h2>
    <b-row>
      <b-col cols="4">Server IP</b-col>
      <b-col>{{site.server.ip}}</b-col>
    </b-row>
    <b-row>
      <b-col cols="4">Domain</b-col>
      <b-col><a target="_blank" :href="`http://` + site.domain">{{ site.domain }}</a></b-col>
    </b-row>
    <b-row>
      <b-col cols="4">SSH User</b-col>
      <b-col>{{ site.username }}</b-col>
    </b-row>
    <b-row>
      <b-col cols="4">Private key</b-col>
      <b-col><b-button size="sm" class="p-0 m-0" variant="link" @click="downloadPrivateKey()">Download private key file</b-button></b-col>
    </b-row>
    <b-row>
      <b-col cols="4">SSH Example</b-col>
      <b-col><span style="font-family: 'Courier New' !important">ssh -i {{ site.username }}.pem {{ site.username }}@{{ site.server.ip }}</span></b-col>
    </b-row>
    <h3 class="mt-3">Database details</h3>
    <b-row>
      <b-col cols="4">Host</b-col>
      <b-col>127.0.0.1</b-col>
    </b-row>
    <b-row>
      <b-col cols="4">Username</b-col>
      <b-col>{{ site.username }}</b-col>
    </b-row>
    <b-row>
      <b-col cols="4">Database</b-col>
      <b-col>{{ site.username }}</b-col>
    </b-row>
    <b-row>
      <b-col cols="4">Password</b-col>
      <b-col>
        <span v-if="showDbPassword">{{ site.db_password }}</span>
        <span v-else>&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;</span>
      </b-col>
      <b-col cols="3">
        <b-button variant="link" class="p-0 m-0" @click="showDbPassword = !showDbPassword" size="sm">
          {{ showDbPassword ? 'hide' : 'show' }}
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="4">Database Management</b-col>
      <b-col>
        <a :href="'https://' + site.server.phpmyadmin" target="_blank">Open PHP My Admin</a><br/>
        <p>You can also connect with your database through SSH. Use the server IP, your usename and your private key.</p>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  props: ['site'],
  computed: {},
  methods: {
    downloadPrivateKey(){
      let element = document.createElement('a');
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(this.site.private_key));
      element.setAttribute('download', this.site.username + '.pem');
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
  },
  data() {
    return {
      showDbPassword: false
    }
  }
}
</script>
