<template>
  <div>
    <div class="page-title">
      <h1>Billing information</h1>
    </div>
    <div class="content">
      <b-row>
        <b-col>
          <b-alert variant="info" class="text-center" show>
            Remember: If you are not satisfied with our services, you can always get a refund!
            <br/>Just send an e-mail to support@laravelhosting.cloud
          </b-alert>
          <h2>Active sites</h2>
          <b-table :items="sites" :fields="['name', 'status', 'actions']">
            <template v-slot:cell(status)="{item}">
              <b-alert variant="success" show v-if="$route.query.payment_complete === item.id.toString()">Set-up successfully complete!</b-alert>
              <b-alert variant="danger" show v-else-if="$route.query.payment_failed === item.id.toString()">Payment set-up failed, try again</b-alert>
              <span v-if="!item.stripe_subscription_id">expires {{ item.renew_at | moment('from') }}</span>
              <span v-else>renews {{ item.renew_at | moment('from') }}</span>
            </template>
            <template v-slot:cell(actions)="{item}">
              <b-button variant="success" v-if="!item.stripe_subscription_id" @click="pay(item)">Set-up payment</b-button>
            </template>
          </b-table>
          <b-alert :show="this.sites.length === 0">
            No active sites found
          </b-alert>
          <h2>Invoices</h2>
        </b-col>
      </b-row>

      <stripe-checkout
          v-if="stripe"
          ref="checkoutRef"
          :pk="stripe.pk"
          :session-id="stripe.session_id"
      />
      {{ stripe }}
    </div>
  </div>
</template>

<script>
import api from '../api'
import { StripeCheckout } from '@vue-stripe/vue-stripe';

export default {
  name: 'Dashboard',
  components: {
    StripeCheckout
  },
  data() {
    return {
      sites: [],
      stripe: null
    };
  },
  methods: {
    pay(site) {
      api.billing().pay(site.id).then(stripe => {
        this.stripe = stripe
      }).finally(() => {
        this.$refs.checkoutRef.redirectToCheckout()
      })
    }
  },
  created() {
    api.site().mine().then(sites => {
      this.sites = sites
    })

    if(this.$route.query.payment_complete) {
      this.$gtag.event('purchase')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
