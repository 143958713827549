<template>
  <div v-if="site">
    <div class="page-title">
      <h1>Domains {{ site.name }}</h1>
    </div>
    <div class="content">
      <b-row v-if="updated">
        <b-col>
          <b-alert show>Domain name updated! It will take a couple of seconds before the change is fully processed.</b-alert>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <p>
            For each domain alias you should create an A record in your DNS. It should point to <b>{{ site.server.ip }}</b>. Do this <b>before</b> adding your domain names!
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <h2>Primary domain <b-spinner class="float-right" show v-if="working"></b-spinner></h2>
          <p>This is the primary domain of your application.</p>
          <b-form-group label="Primary domain">
            <b-form-input v-model="site.domain"></b-form-input>
          </b-form-group>
          <b-form-group>
            <b-badge variant="success" v-if="site.dns">DNS Correctly configured!</b-badge>
            <template v-else>
              <b-badge variant="info">Validating DNS...</b-badge>
              We will validate your DNS every hour for 48 hours. After that we will stop validating.
            </template>
          </b-form-group>
          <b-form-group>
            <b-button variant="success" :disabled="working" @click="update()">Save</b-button>
          </b-form-group>
        </b-col>
        <b-col>
          <h2>Domain aliases</h2>
          <p>Add any additional domain names here.</p>
          <b-form-group label="Add new domain alias">
            <b-form-input v-model="newDomain"></b-form-input>
          </b-form-group>
          <b-form-group>
            <b-button variant="success" :disabled="working" @click="addDomain()">Add alias</b-button>
          </b-form-group>
          <b-table :items="site.domains" :fields="['name', 'actions']" v-if="site.domains.length > 0">
            <template v-slot:cell(actions)="{item}">
              <b-button variant="danger" size="sm" @click="removeDomain(item.id)">Delete</b-button>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <p>
            Once all DNS settings are correct for your domains we will request a Let's Encrypt SSL Certificate for all configured domain names.
          </p>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import api from '../api'

export default {
  computed: {},
  data() {
    return {
      site: null,
      newDomain: '',
      updated: false,
      working: false
    }
  },
  methods: {
    getSite() {
      this.working = true
      api.site().get(this.$route.params.id).then(site => {
        this.site = site
      }).finally(() => {
        this.working = false
      })
    },
    removeDomain(id) {
      this.working = true
      api.site().removeDomain(this.site.id, id).then(() => {
        this.getSite()
      })
    },
    update() {
      this.working = true
      api.site().update(this.site).then((site) => {
        this.site = site
        this.working = false
        this.updated = true
      })
    },
    addDomain() {
      api.site().addDomain(this.site.id, this.newDomain).then(() => {
        this.newDomain = ''
        this.getSite()
        this.updated = true
      })
    }
  },
  created() {
    this.getSite()
  }
}
</script>
