<template>
  <div class="dashboard">

    <div class="page-title">
      <h1>Users</h1>
    </div>

    <b-row>
      <b-col class="content">
        <b-table :items="users" :fields="['name', 'email', 'sites', 'actions']">
          <template v-slot:cell(sites)="{item}">
            <b-row v-for="site in item.sites" :key="site.id">
              <b-col>
                <site-status :working="false" :site="site" :badge="true"></site-status>
              </b-col>
              <b-col>
                <a :href="`http://` + site.domain" target="_blank">{{ site.name }} / {{ site.username }}</a>
              </b-col>
              <b-col>
                {{ site.renew_at }}
              </b-col>
              <b-col>
                <b-button-group>
                  <b-button size="sm" @click="showSiteLog(site)">Deployment log</b-button>
                  <b-button size="sm" variant="info" @click="openForge(site)">Open forge</b-button>
                </b-button-group>
              </b-col>
            </b-row>
          </template>
          <template v-slot:cell(created_at)="{item}">
            {{ item.created_at | moment('from') }}
          </template>
          <template v-slot:cell(actions)="{item}">
            <b-button-group>
              <b-button size="sm" variant="danger" @click="deleteUser(item)">delete</b-button>
              <b-button size="sm" @click="login(item)">Login</b-button>
            </b-button-group>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-modal id="modal-log" title="Latest deployment log" size="xl" :ok-only="true">
      <pre class="my-4">{{ log }}</pre>
    </b-modal>
  </div>
</template>

<script>
import api from '../api'
import SiteStatus from './SiteStatus'

export default {
  name: 'Dashboard',
  components: {
    SiteStatus
  },
  data() {
    return {
      users: [],
      log: null
    }
  },
  methods: {
    showSiteLog(site) {
      api.site().getLastDeployment(site.id).then(log => {
        this.log = log
        this.$bvModal.show('modal-log')
      })
    },
    login(user) {
      api.user().loginAs(user.id).then((token) => {
        this.$store.commit('loginAs', token)
        this.$store.commit('getUser')
      })
    },
    getSites() {
      api.user().all().then(users => {
        this.users = users
      })
    },
    deleteUser(user) {
      if (!confirm('Delete user ' + user.email + '? Are you sure?')) {
        return
      }
      api.user().delete(user.id).then(() => {
        let index = this.users.indexOf(user)
        this.users.splice(index, 1)
      })
    },
    openForge(site) {
      api.site().openForge(site.id).then(response => {
        window.open(response.url, '_blank')
      })
    }
  },
  created() {
    this.getSites()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
