import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
  enabled: !(window.location.host === 'localhost:8080'),
  config: {
    id: "G-KCXWC3CCX5"
  },
}, router)

Vue.config.productionTip = false

Vue.use(require('vue-moment'));

// Install BootstrapVue
Vue.use(BootstrapVue)

// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)


new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
